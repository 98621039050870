angular.module('app').controller('UseVoucherModalController',['$scope','$timeout','webservice','tools','errorlog',
                                            function($scope,$timeout,webservice,tools,errorlog) {
  var script='public/reservationC';

  $scope.newPromoOrVoucherCode = function() {
    return {'code':'','timeout':null,'status':'idle','signature':'','value':'','promo':''};
  };

  $scope.validateVoucher = function($index) {
    var promoOrVoucherCode=$scope.promoOrVoucherCodes[$index];
    var voucherid=promoOrVoucherCode.code.replace(/[^0-9]/g,'');
    var promoid=promoOrVoucherCode.code.replace(/[\s]/g,'');
    if((voucherid.length===12 || promoid.length===8) && tools.getLength(tools.match(tools.exclude($scope.promoOrVoucherCodes,{'code':''}),{'code':promoOrVoucherCode.code}))>1) {
      promoOrVoucherCode.status='invalid';
      promoOrVoucherCode.error=tools.lang({'en':'Codes should not be used twice','nl':'Codes mogen niet twee maal gebruikt worden','fr':'Codes should not be used twice'});
      errorlog.error(promoOrVoucherCode.error);
      return;
    }
    if((voucherid.length===12 || /\d{3}500/.test(voucherid)) && promoOrVoucherCode.timeout===null && $scope.proposeVoucherPayment) {
      promoOrVoucherCode.status='calling';
      webservice.call('validateVoucher',{'index':$index,'voucherid':voucherid},script,{}).then(function(response) {
        if(response!==false) {
          var voucherCode=$scope.promoOrVoucherCodes[response.index];
          if('error' in response) {
            voucherCode.status='invalid';
            voucherCode.error=tools.lang({'en':'Invalid voucher ID','nl':'Ongeldige geschenkbon ID','fr':'ID cheque cadeau invalide'});
            errorlog.error(voucherCode.error);
          } else if('timeout' in response) {
            voucherCode.status='calling';
            voucherCode.timeout=$timeout(function(passIndex) {
              $scope.promoOrVoucherCodes[passIndex].timeout=null;
              $scope.validateVoucher(passIndex);
            },response.timeout,true,response.index);
          } else {
            voucherCode.status='valid';
            voucherCode.signature=response.signature;
            voucherCode.value=response.value;
            voucherCode.promo='';
          }
        }
      });
    } else if(promoid.length===8 && promoOrVoucherCode.timeout===null && $scope.proposePromotionPayment) {
      promoOrVoucherCode.status='calling';
      if(tools.getLength(tools.exclude($scope.promoOrVoucherCodes,{'promo':''}))>1) {
        promoOrVoucherCode.status='invalid';
        promoOrVoucherCode.error=tools.lang({'en':'Promotion codes cannot be combined','nl':'Promotiecodes zijn niet cumuleerbaar','fr':'Les codes promotionnels ne sont pas cumulables'});
        errorlog.error(promoOrVoucherCode.error);
      } else {
        webservice.call('validateCampaign',{'index':$index,'code':promoid},script,{}).then(function(response) {
          if(response!==false) {
            var promoCode=$scope.promoOrVoucherCodes[response.index];
            if('error' in response) {
              promoCode.status='invalid';
              promoCode.error=tools.lang({'en':'Invalid promotion code','nl':'Ongeldige promotie code','fr':'Code promotionnel invalide'});
              errorlog.error(promoCode.error);
            } else if('timeout' in response) {
              promoCode.status='calling';
              promoCode.timeout=$timeout(function(passIndex) {
                $scope.promoOrVoucherCodes[passIndex].timeout=null;
                $scope.validateVoucher(passIndex);
              },response.timeout,true,response.index);
            } else {
              promoCode.status='valid';
              promoCode.signature='';
              promoCode.value='';
              promoCode.promo=response.promo;
            }
          }
        });
      }
    } else {
      promoOrVoucherCode.status='idle';
      promoOrVoucherCode.signature='';
      promoOrVoucherCode.value='';
      promoOrVoucherCode.promo='';
    }
  };

  $scope.getPromoOrVoucherCodePlaceholder = function(index) {
    var hasPromoCode=tools.getLength(tools.exclude($scope.promoOrVoucherCodes,{'promo':''}))>0;
    var promoIndex=0;
    if(hasPromoCode) {
      for(promoIndex;promoIndex<$scope.promoOrVoucherCodes.length;promoIndex++) {
        if($scope.promoOrVoucherCodes[promoIndex].promo!=='') {
          break;
        }
      }
    }
    var placeholder='';
    if($scope.proposeVoucherPayment) {
      placeholder+=tools.lang({'en':'gift voucher','nl':'geschenkbon','fr':'code ch\u00E8que cadeau'});
    }
    if(!$scope.proposePromotionPayment || hasPromoCode) {
      placeholder+=tools.lang({'en':' code','nl':'code','fr':''});
    }
    if($scope.proposeVoucherPayment && $scope.proposePromotionPayment && !hasPromoCode) {
      placeholder+=tools.lang({'en':' or ','nl':' of ','fr':' ou '});
    }
    if($scope.proposePromotionPayment && !hasPromoCode) {
      placeholder+=tools.lang({'en':'promotion code','nl':'promotiecode','fr':'promotionnel'});
    }
    if($scope.promoOrVoucherCodes.length>1) {
      var showIndex=index+1;
      if(hasPromoCode && index>promoIndex) {
        showIndex-=1;
      }
      placeholder+=' '+showIndex;
    }
    return placeholder.charAt(0).toUpperCase()+placeholder.slice(1);
  };

  $scope.vouchersAreInvalid = function() {
    var result=false;
    for(var i in $scope.promoOrVoucherCodes) {
      if($scope.promoOrVoucherCodes[i].code.length>0 && $scope.promoOrVoucherCodes[i].status!=='valid') {
        result=true;
        break;
      }
    }
    return result;
  };

  $scope.close = function() {
    var promoAndVoucherCodes={'promoCode':'','voucherCodes':tools.whitelistNoId(tools.match($scope.promoOrVoucherCodes,{'status':'valid','promo':''}),['code','signature'])};
    var promo=tools.first(tools.exclude($scope.promoOrVoucherCodes,{'promo':''}));
    if(promo) {
      promoAndVoucherCodes.promoCode=promo.code;
    }
    $scope.$close(promoAndVoucherCodes);
  };

  $scope.promoOrVoucherCodes=[$scope.newPromoOrVoucherCode()];
}]);
